
import { computed, defineComponent, ref } from "vue";
import { useTerminalMargins } from "@/components/terminal/terminal-margins";
import { activeActivity } from "@/plugins/i18n";
import { formatDate } from "@/graphql/utils/utils";
import { printElement } from "@/graphql/ticket/ticket.printer";

export default defineComponent({
  name: "TerminalPrinterMargins",
  props: ["modelValue", "margeActive"],
  emits: ["update:modelValue", "update:margeActive"],
  setup(props, { emit }) {
    const { restoreDefault, convertMargins } = useTerminalMargins();
    function restoreMargins() {
      emit("update:modelValue", restoreDefault());
      emit("update:margeActive", 0);
    }
    if (!props.modelValue.length) restoreMargins();

    const marginValues = ref([...props.modelValue]);

    const visible = ref(false);

    function applyMargins() {
      emit("update:modelValue", marginValues.value);
      visible.value = false;
    }
    return {
      restoreMargins,
      visible,
      margins: ["top", "right", "bottom", "left"],
      formatDate,
      printElement,
      convertMargins,
      marginValues,
      currencySymbol: activeActivity.value.currencySymbol,
      applyMargins,
      marginsState: computed(
        () => `terminal.${["disabled", "enabled"][props.margeActive]}`
      ),
    };
  },
});
